import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
	type HTTPLinkOptions,
	TRPCClientError,
	createTRPCReact,
	type httpBatchLink,
	httpLink,
	type inferReactQueryProcedureOptions,
	loggerLink,
} from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { message } from 'antd';
import type { AppRouter } from 'blog/trpc';
import dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { isUndefined, omitBy } from 'lodash-es';
import superjson from 'superjson';
import { FASTIFY_API, isDevelopment } from '../config/local';
import { tryFreshToken } from './try';

export function registerMoreClass() {
	// registerClass(InsertResult, {
	// 	allowProps: ["insertId", "numInsertedOrUpdatedRows"],
	// });
	// registerClass(UpdateResult, {
	// 	allowProps: ["numUpdatedRows", "numChangedRows"],
	// });
}
registerMoreClass();

dayjs.extend(isSameOrAfter.default);
// const wsClient = createWSClient({
//   url: `ws://localhost:2022`,
// });

const queryCache = new QueryCache({
	onError: (error) => {
		if (isDevelopment) {
			// console.log(error, typeof error, error.message, Object.entries(error));
			message.error(`${error.message}`);
		} else {
			message.error(`${error.message}`);
		}

		if (error instanceof TRPCClientError) {
			if (error?.code === 'UNAUTHORIZED') {
				location.href = '/account/sign-in?app=apartment';
			}
		}
	},
	// onSuccess: (data) => {
	//   console.log(data);
	// },
	// onSettled: (data, error) => {
	//   console.log(data, error);
	// },
});

export const TrpcBase = createTRPCReact<AppRouter>({
	abortOnUnmount: true,
});

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

// https://github.com/trpc/trpc/discussions/2036
export const queryClient = new QueryClient({
	queryCache: queryCache,
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

export const trpcConfig = (app: string, isUseAuth: boolean) => {
	const params: Parameters<typeof httpBatchLink>[0] | HTTPLinkOptions = {
		url: `${FASTIFY_API}/trpc`,
		async fetch(url, options) {
			// 不需要用权限
			if (!isUseAuth) {
				return fetch(url, options);
			}
			const idToken = await tryFreshToken(app);

			try {
				return fetch(url, {
					...options,
					headers: omitBy(
						{
							...options?.headers,
							Authorization: idToken && `Bearer ${idToken}`,
						},
						isUndefined,
					),
				});
			} catch (error) {
				if (error?.message) {
					message.error(error?.message);
				}
			}
		},
	};

	return {
		transformer: superjson,
		links: [
			loggerLink({
				enabled: (opts) =>
					opts.direction === 'down' &&
					opts.result instanceof Error &&
					opts?.result?.cause?.message !== 'The user aborted a request.' &&
					opts?.result?.cause?.message !== 'signal is aborted without reason',
			}),
			httpLink(params as HTTPLinkOptions),
			// isDevelopment
			//   ?
			//   : httpBatchLink(params as Parameters<typeof httpBatchLink>[0]),

			// call subscriptions through websockets and the rest over http
			// splitLink({
			//   condition(op) {
			//     return op.type === 'subscription';
			//   },
			//   true: wsLink({
			//     client: wsClient,
			//   }),
			//   false: httpLink({
			//     url: `http://localhost:2022`,
			//   }),
			// }),
		],
	} as Parameters<typeof TrpcBase.createClient>[0];
};
